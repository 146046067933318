.projects-page {
  text-align: center;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 100px;
}

.title {
  font-size: 2.2em;
  color: #1d4ed8 !important;
  margin-bottom: 30px;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.project-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.project-name {
  font-size: 1.2em;
  color: #1d4ed8 !important;
  margin: 0;
}

.links {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
}

.links a {
  color: #2563eb;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #2563eb;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.links a:hover {
  background-color: #2563eb;
  color: #ffffff;
}
