:root {
  --bg-color: transparent;
  --text-color: #1d4ed8;
  --nav-link-color: white;
  --nav-link-hover-color: #dcdcdc;
  --button-bg: #2563eb;
  --button-hover-bg: #1d4ed8;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: var(--bg-color);
  position: relative;
  z-index: 10;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.logo {
  height: 50px;
}

.logo-text {
  font-size: 1.3em;
  font-weight: bold;
  color: var(--text-color);
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-links a {
  color: var(--nav-link-color);
  font-weight: 500;
  font-size: 1.1em;
  text-decoration: none;
  transition: color 0.2s ease;
}

.nav-links a:hover {
  color: var(--nav-link-hover-color);
}

/* Nút menu mobile */
.menu-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  right: 0;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .header {
    padding: 15px 20px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 15px;
    border-radius: 8px;
    z-index: 1000;
    width: auto;
    text-align: right;
  }

  .nav-links.open {
    display: flex;
  }

  .menu-icon {
    display: block;
    font-size: 1.5em;
    color: white;
    cursor: pointer;
  }
}
