body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
