:root {
  --footer-bg-color-light: #1d4ed8;
  --footer-bg-color-dark: #333333;
  --footer-text-color-light: #ffffff;
  --footer-text-color-dark: #cccccc;
}

.footer {
  background-color: var(--footer-bg-color-light);
  color: var(--footer-text-color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
}

body.dark-mode .footer {
  background-color: var(--footer-bg-color-dark);
  color: var(--footer-text-color-dark);
}

.footer-text {
  margin: 0;
  font-size: 0.9em;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icons img {
  width: 24px;
  height: 24px;
  filter: grayscale(100%);
  transition: filter 0.3s ease, transform 0.3s ease;
}

.social-icons img:hover {
  filter: grayscale(0%);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .footer {
    position: relative;
    padding-bottom: 15px;
  }

  .social-icons img {
    width: 20px;
    height: 20px;
  }

  .social-icons {
    gap: 10px;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}
