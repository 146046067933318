.about {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
}

.about h1 {
  font-size: 2em;
  color: #1d4ed8;
  margin-bottom: 20px;
}

.about p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin: 10px 0;
}
