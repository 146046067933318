:root {
  --bg-color: transparent;
  --text-color: white;
  --header-bg: transparent;
  --subtitle-color: white;
  --project-bg: rgba(255, 255, 255, 0.1);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.home {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
}

.home-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  padding: 15px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.main-logo {
  width: 50px;
  height: auto;
  transition: all 0.3s ease;
}

.title {
  font-size: 1.8em;
  margin-top: 30px;
  color: var(--text-color);
}

.subtitle {
  font-size: 1.2em;
  margin-top: 10px;
  color: var(--subtitle-color);
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.project-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--project-bg);
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.links a {
  color: white;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid white;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.links a:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .home-header {
    padding: 10px;
  }
  .projects {
    gap: 15px;
  }
}
