html, body, #root {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  background: transparent;
  color: white;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: transparent;
}

.main-content {
  flex: 1;
  background: transparent;
  position: relative;
  z-index: 1;
}

header {
  background: transparent !important;
  position: relative;
  z-index: 2;
}

footer {
  width: 100%;
  text-align: center;
  background: transparent !important;
  color: white;
  backdrop-filter: blur(5px);
  padding: 20px;
  z-index: 2;
}

.card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

h1, h2, h3, p {
  color: white !important;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .main-content {
    padding-bottom: 80px;
  }

  footer {
    padding: 10px 5px;
  }
}
